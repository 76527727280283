import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"


const Page = ({data}) => {
  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
  ]
  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 
  const html = block(lang,t)

  return (
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title={t("サイトマップ")} lang={lang} />
      <ContentLayout lang={lang}>
        <h1>{t("サイトマップ")}</h1>
        <ul className="sitemap">
          <li>
            <Link to="/campus/">{t("筑波キャンパスについて")}</Link>
            <ul>
              <li>
                <Link to="/campus/history/">{t("沿革")}</Link>
              </li>
              <li>
                <Link to="/campus/organization/">{t("組織図")}</Link>
              </li>
              <li>
                <Link to="/campus/facilities/">{t("所内施設")}</Link>
              </li>
              <li>
                <Link to="/campus/disclosure/">{t("公開情報")}</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/centers/">{t("センター紹介")}</Link>
          </li>
          <li>
            <Link to="/experience/">{t("研究成果の普及")}</Link>
            {html}
          </li>
          <li>
            <Link to="/partnerships/">{t("地域での活動")}</Link>
          </li>
        </ul>
        <ul className="sitemap sub">
          <li>
            <Link to="/access/">{t("アクセス")}</Link>
          </li>
          <li>
            <Link to="/contact/">{t("お問い合わせ")}</Link>
          </li>
          <li>
            <Link to="/topics/">{t("お知らせ")}</Link>
          </li>
          <li>
            <Link to="/accessibility/">{t("ウェブアクセシビリティ")}</Link>
          </li>
        </ul>
      </ContentLayout>
    </Layout>
    )
}
export default Page


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }
`;

function block(language,t) {
  if(language==='en'){
    return (
      <></>
    )
  }else{
    
    return (
      <>
        <ul>
          <li>
            <Link to="/experience/application/">{t("見学お申込み")}</Link>
          </li>
        </ul>
      </>
    )
  }
}